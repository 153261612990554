import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { UrlType, generalObj } from "../constant/objects_types";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEventUser, navigateRoute } from "../action/generalFunc";
import { ButtonFrame, FooterAction, ModalLoader, ProfileGenerator, TicketType, CheckOut, PaymentConfirm } from "../components";

const BuyTicket = () => {

    const [ticket, setTicket] = useState<generalObj[] | null>(null);
    const [eventName, setEventName] = useState<string>("");
    const [proceed, setProceed] = useState<boolean>(false);
    const [openCheckOut, setOpenCheckOut] = useState<boolean>(false);
    const [openPayConfirm, setOpenPayConfirm] = useState<boolean>(false);
    const [openPayConfirmData, setOpenPayConfirmData] = useState<generalObj | null>(null);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [userCharge, setUserCharge] = useState<boolean>(false);

    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    let { id } = useParams();

    const parentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!account || !id) return;
        // if (!account.isAuthenticated) {
        //     navigate(setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.BUY_TICKET}${id}`))
        //     return;
        // } 
        setModalLoading(true);
        (async () => {
            let result:generalObj = await fetchEventUser(account.token, `${id}`);
            if (result.status && result.data.data.event.tickets) {
                setEventName(result.data.data.event.name);
                setUserCharge(result.data.data.event.charge_user);

                let obj_ticket:generalObj[] = result.data.data.event.tickets.sort((a:generalObj, b:generalObj) => {
                    return b.category - a.category
                });
                obj_ticket.filter((tick:generalObj, index:number) => {
                    if (index === (obj_ticket.length - 1)) {
                        tick.active = true;
                        tick.qty = 1;
                    }else {
                        tick.active = false;
                    }
                    return tick;
                })
                setTicket(obj_ticket);
                setProceed(true);
            }else {
                setTicket(null);
                alert(result.message);
                navigate(UrlType.HOME);
            }
            setModalLoading(false);
        })();
    }, [account, id, navigate]);


    const updateTicket = (id:number, qty:number) => {
        setTicket((prevTicket) => {
            if (!prevTicket) return null;
            return (
                prevTicket.filter((item) => {
                    if (item.id === id) {
                        item.active = true;
                        item.qty = qty;
                        setProceed(true);
                    }else {
                        item.active = false;
                    }
                    return item;
                })
            )
        })
    }

    return (
        <>
            <div className={`appBody loading`} style={{position:'relative'}} ref={parentRef}>
                {ticket && (
                    <div style={{height:'100%'}}>
                        <div style={{padding:"0px 20px"}}>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px"}}>
                                <div style={{flexDirection:'row', alignItems:'center'}}>
                                    <span className="sectionTitle" style={{width:'80%'}}>{eventName}</span>
                                </div>
                                <button onClick={() => navigate(navigateRoute())}>
                                    <IoCloseOutline size={"26px"} color={"#FFF"} />
                                </button>
                            </div>

                            <div className="sectionTitle" style={{color:'rgba(255, 255, 255, 0.6)', marginTop:"32px", marginBottom:"16px", fontSize:"16px", lineHeight:"16px"}}>My Ticket</div>
                            <div>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:"16px"}}>
                                    <button style={{display:'flex', alignItems:'center'}}>
                                        <ProfileGenerator constStyle={{width:"22px", height:"22px", display:'flex', justifyContent:'center', alignItems:'center'}} textStyle={{fontSize:"12px", lineHeight:"16px"}} useAccount={true} />
                                        <div className="postOwnerText" style={{marginLeft:"5px"}}>{account && account.isAuthenticated ? account.fullname : "Anonymous User"}</div>
                                    </button>
                                </div>
                                {ticket && (
                                    <div style={{overflowX: "hidden", height: "500px"}}>
                                        <div style={{ paddingBottom: "120px" }}>
                                            {ticket.map((item, index) => (
                                                item.category && (
                                                    <TicketType key={index} counter={index} item={item} onPress={(id, qty) => updateTicket(id, qty)} />
                                                )
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <FooterAction contStyle={{width:"calc(100% - 40px)", paddingBlock: "20px"}}>
                            <>
                                <ButtonFrame title="Checkout" contStyle={{marginTop: "0px", backgroundColor:'#DB012E', border:"none", opacity:proceed?"1":"0.6"}} onPress={()=>setOpenCheckOut(true)} />
                            </>
                        </FooterAction>
                    </div>
                )}
            </div>
            <CheckOut visible={openCheckOut} myTicketCheck={ticket} charge_user={userCharge} updateMyTicket={(val) => {setTicket(val ? [...val] : null) }} isLoading={(val) => setModalLoading(val)} modalClose={() => setOpenCheckOut(false)} checkOut={(val) => {setOpenPayConfirm(true); setOpenPayConfirmData(val)}}  />
            {openPayConfirmData && <PaymentConfirm visible={openPayConfirm} data={openPayConfirmData} />}
            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default BuyTicket;