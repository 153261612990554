import axios from 'axios';
import { API_URL, GOOGLE_MAP_API_KEY, PAYSTACK_SECRET_KEY, PROCESSING_FEE, RESALE_URL, SERVICE_FEE, radarAPIKEY } from '../constant/constant';
import { UrlType, generalObj } from '../constant/objects_types';
import { countryShortName, monthsCode } from '../constant/shortcodes';
import copy from 'copy-to-clipboard';


/* Non API function */

export const fetchUserLocation = async () => {
  // const successCallback = async (position:any) => {
  //   let get_country:generalObj = await getReverseGeocoding(`${position.coords.latitude}, ${position.coords.longitude}`)
  //   if (get_country.status) {
  //     localStorage.setItem('userLocation', get_country.data);
  //   }else {
  //     localStorage.setItem('userLocation', "NG");
  //   }
  // };
  
  // const errorCallback = (error:any) => {
  //   localStorage.setItem('userLocation', "NG");
  // };

  //await navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  let get_location:generalObj = await getIpGeocoding();
  if (get_location.status) {
    localStorage.setItem('userLocation', JSON.stringify(get_location.data));
  }else {
    localStorage.setItem('userLocation', JSON.stringify({
      countryCode:"NG",
      country: "Nigeria",
      city: "Lagos"
    }))
  }
  return;
}

export const validateEmail = (value:string) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
}

export const slugify = (str:string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const checkDeviceApple = () => {
  const expression = /(Mac|MacIntel|iPhone|iPod|iPad)/i;

  if (expression.test(navigator.platform)) {
    return true;
  } else {
    return false;
  }
}

export const validatePassword = (value:string) => {
    //let re = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&-_?*])(?=.{8,})");
    let capReg = new RegExp("(?=.*[a-z])(?=.*[A-Z])");
    let numReg = new RegExp("(?=.*[0-9])");
    let lengthReg = new RegExp("(?=.{8,})");
    let specialReg = /!|@|#|%|&|-|_|\*|\$|\?/;
    return {
      isSpecial: specialReg.test(value),
      isUpper: capReg.test(value),
      isNumber: numReg.test(value),
      isLength: lengthReg.test(value),
    };
}

export const getDateStr = (dateObj:string, format:boolean=false)  => {
  const new_date:Date = new Date(dateObj);
  let date:string = '';
  if (format) {
    date = `${new_date.getDate() < 10? `0${new_date.getDate()}`:new_date.getDate()} ${monthsCode[new_date.getMonth() + 1]} ${new_date.getFullYear()}`;
  }else {
    date = `${new_date.getDate() < 10? `0${new_date.getDate()}`:new_date.getDate()}-${new_date.getMonth() + 1 < 10? `0${new_date.getMonth() + 1}`:new_date.getMonth() + 1}-${new_date.getFullYear()}`;
  }
  return date;
};

export const getTimeStr = (timeObj:string, format:boolean=false)  => {
  const new_time:Date = new Date(timeObj);
  
  let hour:string = '';
  let nformat:string = '';
  if (new_time.getHours() >= 12) {
    hour = new_time.getHours() === 12 ? `${new_time.getHours()}` :`${new_time.getHours() - 12 < 10 ?`0${new_time.getHours() - 12}`:new_time.getHours() - 12}`;
    nformat = "PM"
  }else {
      if (new_time.getHours() < 10) {
          hour = `0${new_time.getHours()}`;
      }else {
          hour = `${new_time.getHours()}`
      }
      nformat = "AM";
  }
  let time:string = "";
  if (format) {
    time = `${hour} ${nformat}`;
  }else {
    time = `${hour}:${new_time.getMinutes() < 10? `0${new_time.getMinutes()}`: new_time.getMinutes()} ${nformat}`;
  }
  return time;
};


export const getUTCStr = (timeObj:string)  => {
  let obj_string = timeObj.replaceAll(" ", "T");
  return `${obj_string}.000Z`;
};

export const checkEventPast = (eventItem:generalObj) => {
  const endDate = new Date(getUTCStr(`${eventItem.end_date}`))
  const tznow = new Date();

  if (tznow.getTime() > endDate.getTime()) {
    return true
  }else {
    return false
  }
}

export const processAddressnMap = (location:string, return_type:string | null=null) => {
  let loc_list:Array<string> = location.split('[&]');
  let result:generalObj = {};
  loc_list.forEach(elemt => {
    let splitted_ele = elemt.split("=");
    result[splitted_ele[0]] = splitted_ele[1];
  });

  if (return_type && result[return_type]) {
    return result[return_type];
  }else {
    return result;
  }
}

export const getLocation = async () => {
    try {
      const location = await localStorage.getItem('userLocation');
      if (location !== null && location !== 'undefined') {
        const new_location = JSON.parse(location);
        
        return new_location;
      }else {
        return null;
      }
    }catch(e) {
      return null;
    }
}

export const getLocationStates = async (locIOS:string) => {
  let country:generalObj = countryShortName[locIOS];
  return country.states;
}

export const getPhoneCode = async (locIOS?:string) => {
  if (locIOS) {
    let country:generalObj = countryShortName[locIOS];
    return([{
      id: 0,
      phone_code: `+${country.phone_code.replaceAll("+", "").split("-")[0]}`,
      flag: country.emoji,
      code: locIOS
    }])
  }
  let country_list = Object.entries(countryShortName)

  let result:generalObj[] = [];

  country_list.forEach((element:string | any, index:number ) => {
    let country:generalObj = element[1];
    result.push({
      id: index + 1,
      phone_code: `+${country.phone_code.replaceAll("+", "").split("-")[0]}`,
      flag: country.emoji,
      code: element
    })
  });

  return result;
}

export const getCountryfromCode = (code:string) => {
  
  let country_list = Object.entries(countryShortName)

  let result:generalObj = {};

  country_list.forEach((element:string | any, index:number ) => {
    let country:generalObj = element[1];
    let sum_code = country.phone_code.replaceAll("+", "").split("-")[0];
    if (code === sum_code) {
      result.id = index + 1;
      result.phone_code = `+${sum_code}`;
      result.flag = country.emoji;
    }
  });

  return result;
}

export const copyToClipboard = async (text:string) => {
  await copy(text);
};

export const postedTime = (postTime:string) => {
  let tz_now:Date = new Date();
  let post_time:Date = new Date(`${postTime}`);
  let diff_time = (tz_now.getTime() - post_time.getTime()) / 1000;

  if (diff_time < 60) {
    return `${Math.floor(diff_time)} ${Math.floor(diff_time) > 1 ? 'secs' : 'sec'} ago`;
  }else if (diff_time / 60 < 60) {
    return `${Math.floor(diff_time / 60)} ${Math.floor(diff_time / (60)) > 1 ? 'mins' : 'min'} ago`;
  }else if (diff_time / (60 * 60) < 24) {
    return `${Math.floor(diff_time / (60 * 60))} ${Math.floor(diff_time / (60 * 60)) > 1 ? 'hrs' : 'hr'} ago`;
  }else if (diff_time / (60 * 60 * 24) < 7) {
    return `${Math.floor(diff_time / (60 * 60 * 24))} ${Math.floor(diff_time / (60 * 60 * 24)) > 1 ? 'days' : 'day'} ago`;
  }else if (diff_time / (60 * 60 * 24 * 7) < 4) {
    return `${Math.floor(diff_time / (60 * 60 * 24 * 7))} ${Math.floor(diff_time / (60 * 60 * 24 * 7)) > 1 ? 'wks' : 'wk'} ago`;
  }else if (diff_time / (60 * 60 * 24 * 7 * 4) < 12) {
    return `${Math.floor(diff_time / (60 * 60 * 24 * 7 * 4))} ${Math.floor(diff_time / (60 * 60 * 24 * 7 * 4)) > 1 ? 'mts' : 'mt'} ago`;
  }else {
    return `${Math.floor(diff_time / (60 * 60 * 24 * 7 * 4 * 12))} ${Math.floor(diff_time / (60 * 60 * 24 * 7 * 4 * 12)) > 1 ? 'yrs' : 'yr'} ago`;
  }
}

export const convertTicketCategory = (title:string, main_title:string|null = null) => {
  let result = "";
  switch (title) {
    case 'vvip':
      result = "V.V.I.P";
      break;
    case 'vip':
      result = "V.I.P";
      break;
    case 'regular':
      result = "Regular";
      break;
    default:
      result = main_title ? main_title : "No Category";
  }
  return result;
}


export const validateUrl = (value:string) => {
  let re = /^((https?|ftp):\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?([-a-z\d_]*)?$/i;
  return(re.test(value));
}


export const getBlobUri = (val:Blob) => {
  const url = URL.createObjectURL(val);
  return url
}

export const calculateSellingPrice = (price:number, to_string:boolean=true) => {
  let total;
  if (price > 0) {
      let initial_cost = parseFloat(price.toString()) + ((price * (SERVICE_FEE + PROCESSING_FEE))/100);
      let total_fee = initial_cost.toFixed(2).toString();
      total = to_string ? parseFloat(total_fee).toLocaleString() : parseFloat(total_fee);
  }else {
      total = 'Free';
  }
  return total;
}

export const altCalculateSellingPrice = (price:number, to_string:boolean=true) => {
  let total;
  if (price > 0) {
      let commission = (parseFloat(price.toString()) * (1 + ((SERVICE_FEE + PROCESSING_FEE) / 100))) - parseFloat(price.toString());
      let total_fee = (parseFloat(price.toString()) - commission).toFixed(2).toString();
      total = to_string ? parseFloat(total_fee).toLocaleString() : parseFloat(total_fee);
  }else {
      total = 'Free';
  }
  return total;
}

export const deriveInitialPrice = (total:number, charge_user:boolean = false) => {
  let percentage = SERVICE_FEE + PROCESSING_FEE;
  if (charge_user) {
    return (total * 100) / (100 + percentage);
  }else {
    let commission = (total * (1 + (percentage / 100))) - total;
    return total - commission;
  }
}

export const deriveInitialCharge = (total:number, charge_user:boolean = false) => {
  let result = deriveInitialPrice(total, charge_user);
  return total - result;
}

export const setUpPrevUrl = (current_url:string, redirect:string) => {
  localStorage.setItem("falseredirect", current_url);
  return redirect;
}

export const navigateRoute = () => {
  let result = localStorage.getItem("falseredirect");
  localStorage.removeItem("falseredirect");
  if (result && result !== window.location.pathname) {
    return result
  }else {
    return UrlType.HOME
  }
}

// export const triggerShare = async (itemValues) => {
//   const eventUrl = Linking.createURL('/Event_Detail', {
//       queryParams: { id: itemValues.id },
//   });
//   const result = await Share.share({
//     message: `${eventUrl} \n\n${itemValues.name} - ${itemValues.start_date} - ${itemValues.start_time} \n\n${itemValues.description}`,
//   }).then((result) => (result)).catch((error) => (error));

//   return result;
// };

export const getEventDetail = (event:generalObj, url:string=UrlType.EVENT_DETAIL) => {
  if (event.visibility === 'private') {
    return `${url}${event.event_slug}`;
  }else{
    return `${url}${event.id}`;
  }
}

/* End of Non API function */



/* API based functions */

export const checkUsername = async ( username:string ) => {
  var config = {
    method: 'GET',
    url:`${API_URL}username/${username}`,
    headers: {  
      'Content-Type': 'application/json'
    }
  };
  let result = await axios(config)
  .then((response) => {
    const resp = {
      status: true,
      message: response.data ? "Awesome! This username is not taken" : "This username is taken",
      valid: response.data
    }
    return resp;
  })
  .catch((error) => {
    let resp;
    if (error.response.data.error) {
      resp = {
        status: true,
        message: error.response.data.message,
        valid: false
      }
    }else {
      resp = {
        status: true,
        message: 'Error connecting to server, try again later',
        valid: false
      }
    }
    
    return resp;
  })
  return result;
}

export const checkEmailApi = async ( email:string ) => {
  var config = {
    method: 'GET',
    url:`${API_URL}email?email=${email}`,
    headers: {  
      'Content-Type': 'application/json'
    }
  };
  let result = await axios(config)
  .then((response) => {
    const resp = {
      status: true,
      isExist: !response.data
    }
    return resp;
  })
  .catch((error) => {
    let resp;
    if (error.response.data.error) {
      resp = {
        status: false,
        message: error.response.data.message
      }
    }else {
      resp = {
        status: false,
        message: 'Error connecting to server, try again later',
      }
    }
    
    return resp;
  })
  return result;
}

export const getReverseGeocoding = async (coords:string) => {
  var config = {
    method: 'GET',
    url:`https://api.radar.io/v1/geocode/reverse?coordinates=${coords}`,
    headers: { 
      'Authorization': `${radarAPIKEY}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data.addresses.countryCode
    }
  })
  .catch((error) => {
      return {
        status: false,
        message: "Unable to fetch Bank data"
    }
  })

  return result;
}

export const getIpGeocoding = async () => {
  var config = {
    method: 'GET',
    url:`https://api.radar.io/v1/geocode/ip`,
    headers: { 
      'Authorization': `${radarAPIKEY}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data.address
    }
  })
  .catch((error) => {
      return {
        status: false,
        message: "Unable to fetch location data"
    }
  })

  return result;
}

export const completeKyc = async (data:generalObj, token:string) => {
  var config = {
      method: 'post',
      url:`${API_URL}kyc`,
      headers: { 
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'
      },
      data: {
        event_interests: data
      }
  };
  
  let is_Success = await axios(config)
  .then((response) => {
      return true;
  })
  .catch((error) => {
      alert('Unable to complete your KYC at the moment');
      return false
  })

  return is_Success;
}

export const fetchLanding = async (token:string | null) => {
  let headers:generalObj = {  
    'Content-Type': 'application/json'
  }
  
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  var config = {
    method: 'get',
    url:`${API_URL}home`,
    headers: headers
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch event events."
      }
  })

  return result;
}


export const fetchEventUser = async (token:string, eventId:string) => {
  let headers:generalObj = {  
    'Content-Type': 'application/json'
  }
  
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  var config = {
    method: 'get',
    url:`${API_URL}event/get/${eventId}`,
    headers: headers
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      if (error.response.data.message) {
        return {
          status:false,
          message: error.response.data.message
        }
      }else {
        return {
          status:false,
          message: "Unable to fetch event events."
        }
      }
  })

  return result;
}

export const fetchEventUserTickets = async (token:string, eventId:string, page:number, per_page:number) => {
  let headers:generalObj = {  
    'Content-Type': 'application/json'
  }
  
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  var config = {
    method: 'get',
    url:`${API_URL}event/user_tickets/${eventId}?page=${page}&per_page=${per_page}`,
    headers: headers
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.message) {
      return {
        status:false,
        message: error.response.data.message
      }
    }else {
      return {
        status:false,
        message: "Unable to fetch event user tickets."
      }
    }
      
  })

  return result;
}

export const fetchUser = async (token:string, Id:string) => {
  let headers:generalObj = {  
    'Content-Type': 'application/json'
  }
  
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  var config = {
    method: 'get',
    url:`${API_URL}id/${Id}`,
    headers: headers
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Fetching user information failed."
      }
  })

  return result;
}

export const updateEventSaved = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}saved/events`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to save event."
      }
  })

  return result;
}

export const fetchEventType = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}event-types`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch event types."
      }
  })

  return result;
}

export const fetchEventSubCategory = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}event-type-categories`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch event sub category."
      }
  })

  return result;
}

export const fetchAllEvent = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}events`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch event."
      }
  })

  return result;
}

export const fetchMyEvent = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}my-events`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch your event."
      }
  })

  return result;
}

export const fetchEventByUserId = async (token:string, id:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}events/user/${id}`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
      return {
        status: true,
        data: response.data
      }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to fetch users event."
      }
  })

  return result;
}

export const getNgBanks = async () => {
  var config = {
    method: 'GET',
    url:'https://api.paystack.co/bank?currency=NGN',
    headers: { 
      'Authorization': `Bearer ${PAYSTACK_SECRET_KEY}`, 
      'Content-Type': 'application/json'
    },
    params : {}
  };

  let result = await axios(config)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
      return {
        status: false,
        message: "Unable to fetch Bank data"
    }
  })

  return result;
  
}

export const getAccountName = async (accNum:string, bankCode:string) => {
  var config = {
    method: 'GET',
    url:`https://api.paystack.co/bank/resolve?account_number=${accNum}&bank_code=${bankCode}`,
    headers: { 
      'Authorization': `Bearer ${PAYSTACK_SECRET_KEY}`, 
      'Content-Type': 'application/json'
    }
  };
  let result = await axios(config)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
      return {
        status: false,
        message: "Unable to fetch Account name"
    }
  })
  return result;
}

export const addPayAccount = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}bank-account/add`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      return {
        status: false,
        message: error.response.data.message
      };
    }else {
      return {
        status: false,
        message: 'Unable to add payment account.'
      };
    }
  })

  return result;
}

export const getPayAccounts = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}bank-account/list`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      return {
        error: true,
        message: error.response.data.message
      };
    }else {
      return {
        error: true,
        message: 'Unable to fetch payment accounts.'
      };
    }
  })

  return result;
}

export const resetPasswordRequest = async (email:string) => {
  var config = {
      method: 'post',
      url:`${API_URL}password/reset-request`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: {
        email: email
      }
  };
  
  let is_Success = await axios(config)
  .then((response) => {
      return true;
  })
  .catch((error) => {
    if (error.response && error.response.data.error) {
      alert(error.response.data.message);
    }else {
        alert('Error connecting to server, try again later');
    }
    return false
  })

  return is_Success;
}

export const forwardGeocoding = async (searchText:string, country:string) => {
  var config = {
    method: 'get',
    url:`https://api.radar.io/v1/search/autocomplete?query=${searchText}&country=${country.toUpperCase()}`,
    headers: {
      'Authorization': `${radarAPIKEY}`,
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    let data:generalObj = [];

    if (response.data.addresses) {
      response.data.addresses.forEach((elem:generalObj) => {
        let cur_elem = {
          id: Math.ceil(Math.random() * 1000000),
          coordinates: elem.geometry.coordinates,
          formattedAddress: elem.formattedAddress,
          addressLabel: elem.addressLabel ? elem.addressLabel : elem.placeLabel
        }
  
        data.push(cur_elem);
      });
    }
    

    return {
      status: true,
      data: data 
    }
  })
  .catch((error) => {
      return {
        status: false
      }
  })

  return result;
}

export const googleMapPlaces = async (searchText:string) => {
  var config = {
    method: 'POST',
    url:`https://places.googleapis.com/v1/places:searchText`,
    headers: {
      'X-Goog-Api-Key': `${GOOGLE_MAP_API_KEY}`,
      'X-Goog-FieldMask': "places.displayName,places.formattedAddress,places.location",
      'Content-Type': 'application/json'
    },
    data: {
      "textQuery" : searchText
    }
  };

  let result = await axios(config)
  .then((response) => {
    let data:generalObj = [];

    if (response.data.places) {
      response.data.places.forEach((elem:generalObj) => {
        let cur_elem = {
          id: Math.ceil(Math.random() * 1000000),
          coordinates: elem.location,
          formattedAddress: elem.formattedAddress,
          addressLabel: elem.displayName ? elem.displayName.text : elem.formattedAddress
        }
  
        data.push(cur_elem);
      });
    }
    

    return {
      status: true,
      data: data 
    }
  })
  .catch((error) => {
      return {
        status: false
      }
  })

  return result;
}

export const searchUser = async (token:string, query:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}search?search_query=${query}`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to search the record."
      }
  })

  return result;
}

export const processEventData = async (section1:generalObj, section2:generalObj, section3:generalObj) => {
  let scanners:Array<string> = [];
  
  if (section3.canScan) {
    section3.canScan.forEach((element:generalObj) => {
      if (!element) return;
      scanners.push(`${element.id}`);
    });
  }
  try {
    let event_data:generalObj = {
      "name": section1.name,
      "description": section1.description,
      "event_type_id": section1.eventType.id,
      "event_category_id": section1.eventSub ? section1.eventSub.id : null,
      "authorized_scanners": scanners,
      "location_type": section1.location.online ? "online": "venue",
      "location": section1.location.online ? "": `addressLabel=${section1.venue_location.addressLabel}[&]coordinates=${section1.venue_location.coordinates}[&]formatAddress=${section1.venue_location.formattedAddress}`,
      "online_link": section1.location.online ? section1.online_location_link : "",
      "date_type": section1.date.oneTime ? "one-time" : "recurring",
      "start_date": "",
      "charge_user":section3.UsersIncurCharge ? "1" : "0",
      "frequency": "",
      "visibility": section1.visibility.private ? 'private' : 'public',
      "end_type": "",
      "occurence": "",
      "tags": section1.tags,
      "tickets": [],
      "has_ticket": section3.eventHasTicket ? "1" : "0",
      "ticket_has_category": section3.ticketCategory ?  "1" : "0"
    }

    // Prepare Event details 
    if (section1.date.recurring) {
      event_data['start_date'] = section1.recurring.startDate.toISOString();
      event_data['end_type'] = "occurence";
      event_data['occurence'] = section1.recurring.occurence.label;
      if (section1.recurring.withEndDate) {
        event_data['end_date'] = section1.recurring.endDate.toISOString();
        event_data['frequency'] = "0";
      }else {
        event_data['frequency'] = section1.recurring.endOccurence;
      }
    }else {
      event_data['start_date'] = section1.oneTime.startDate.toISOString();
      event_data['end_date'] = section1.oneTime.endDate.toISOString();
      event_data['end_type'] = "end_date";
      event_data['frequency'] = "1";
      event_data['occurence'] = "";
    }

    if (section1.tags) {
      event_data["tags"] = section1.tags 
    }

    // Prepare event assets

    if (section2.coverImage) {
      event_data['cover_image'] = section2.coverImage;
    }

    if (section2.promotionVideo) {
      event_data['promotion_video'] = section2.promotionVideo;
    }

    //Prepare event tickets
    if (section3.eventHasTicket) {
      if (section3.ticketCategory) {
        let nTickets:generalObj[] = [];
        section3.categories.forEach((element:generalObj) => {
          if (!element.isDeleted) {
            let ticket_item:generalObj = {
              "category": element.slug,
              "title": element.title,
              "quantity": element.ticketNum,
              "description": element.description,
              "price": parseFloat(element.price) === 0 ? 0 : section3.UsersIncurCharge ? calculateSellingPrice(parseFloat(element.price), false) : parseFloat(element.price)
            }
            if (element.id) {
              ticket_item["id"] = element.id;
            }
            nTickets.push(ticket_item);
          }
          event_data['tickets'] = nTickets;
        });
      }else {
        let ticket_item:generalObj = {
          "category": section3.noCategory.slug,
          "title": section3.noCategory.title,
          "quantity": section3.noCategory.ticketNum,
          "description": section3.noCategory.description,
          "price": parseFloat(section3.noCategory.price) === 0 ? 0 : section3.UsersIncurCharge ? calculateSellingPrice(parseFloat(section3.noCategory.price), false) : parseFloat(section3.noCategory.price)
        }
        if (section3.noCategory.id) {
          ticket_item["id"] = section3.noCategory.id;
        }
        event_data['tickets'] = [
          ticket_item
        ]
      }
    }
    
    return {
      error: false,
      data: event_data
    };
  }catch {
    return {
      error: true,
      message: "Error when creating event"
    }
  }
}

export const processResellData = async (section1:generalObj, section2:generalObj, section3:generalObj, email:string, user:string) => {
  let tag = "";
  if (section1.tags && section1.tags.length > 0) {
    section1.tags.forEach((element:string, index:number) => {
      tag += index === 0 ? element : `, ${element}`;
    });
  }
  let performer = "";
  if (section1.performers && section1.performers.length > 0) {
    section1.performers.forEach((element:string, index:number) => {
      performer += index === 0 ? element : `, ${element}`;
    });
  }
  try {
    let event_data:generalObj = {
      "user_email": email,
      "user_id": user,
      "name": section1.name,
      "event_type": section1.eventType.id,
      "event_sub_type": section1.eventSub.id,
      "price": section1.price,
      "start_date": section1.oneTime.startDate.toISOString(),
      "end_date": section1.oneTime.endDate.toISOString(),
      "tags": tag,
      "performers": performer,
      "number": `${section3.phone.value}-${section3.phone_number}`,
      "email": `${section3.email}`,
      "information": `${section3.information}`
    }

    if (section2.coverImage) {
      event_data.image = section2.coverImage
    }

    return {
      error: false,
      data: event_data
    };
  }catch {
    return {
      error: true,
      message: "Error when creating ticket sale"
    }
  }
}

export const createEvent = async (data:generalObj, token:string) => {
  let formData = new FormData();
  for (let key in data) {
    if (key === 'cover_image' && data[key]) {
      formData.append("cover_image", data[key]);

    }else if (key === 'promotion_video' && data[key]) {
      formData.append("promotion_video", data[key]);

    }else if (key === 'tickets') {
      data[key].forEach((element:generalObj, index:number) => {
        formData.append(`tickets[${index}][category]`, element.category);
        formData.append(`tickets[${index}][title]`, element.title);
        formData.append(`tickets[${index}][quantity]`, element.quantity);
        formData.append(`tickets[${index}][description]`, element.description);
        formData.append(`tickets[${index}][price]`, element.price);
      });

    }else if (key === 'tags') {
      
      data[key].forEach((element:string) => {
        formData.append(`${key}[]`, element);
      });
    }else if (key === 'authorized_scanners') {
      
      data[key].forEach((element:string) => {
        formData.append(`${key}[]`, element);
      });
    }
    else {
      formData.append(key, typeof(data[key]) === 'boolean' ? data[key].toString() : data[key]);
    }
  }

  var config = {
      method: 'post',
      url:`${API_URL}event/create`,
      headers: { 
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'multipart/form-data'
      },
      data: formData
  };
  let result = await axios(config)
  
  .then((response) => {
    if (response.data.error) {
      let err_msg = '';
      for (let key in response.data.message) {
        err_msg += err_msg.length !== 0 ? ` - ${response.data.message[key][0]}` : response.data.message[key][0];
        return {
          error: true,
          message: err_msg
        };
      }
    }else {
      return {
        error: false,
        message: 'Event Created'
      };
    }
      
  })
  .catch((error) => {
    try {
      if (error.response.data.error) {
        return {
          error: true,
          message: error.response.data.message
        };
      }else {
        return {
          error: true,
          message: 'Unable to complete event creation'
        };
      }
    }catch {
      return {
        error: true,
        message: 'Server error'
      };
    }
    
  })
  return result;
}

export const createResale = async (data:generalObj, method:string) => {
  let formData = new FormData();
  for (let key in data) {
    if (key === 'cover_image' && data[key]) {
      formData.append("image", data[key]);

    }else {
      formData.append(key, typeof(data[key]) === 'boolean' ? data[key].toString() : data[key]);
    }
  }

  var config = {
      method: method,
      url:`${RESALE_URL}ticket_sale/`,
      headers: { 
        'Content-Type': 'multipart/form-data'
      },
      data: formData
  };
  let result = await axios(config)
  
  .then((response) => {
    return {
      error: false,
      message: response.data.message
    };
  })
  .catch((error) => {
    try {
      return {
        error: true,
        message: error.response.data.message
      };
    }catch {
      return {
        error: true,
        message: 'Server error'
      };
    }
    
  })
  return result;
}

export const updateEvent = async (data:generalObj, token:string, id:string) => {
  let formData = new FormData();
  for (let key in data) {
    if (key === 'cover_image' && data[key]) {
      formData.append("cover_image", data[key]);

    }else if (key === 'promotion_video' && data[key]) {
      formData.append("promotion_video", data[key]);

    }else if (key === 'tickets') {
      data[key].forEach((element:generalObj, index:number) => {
        formData.append(`tickets[${index}][id]`, element.id);
        formData.append(`tickets[${index}][category]`, element.category);
        formData.append(`tickets[${index}][title]`, element.title);
        formData.append(`tickets[${index}][quantity]`, element.quantity);
        formData.append(`tickets[${index}][description]`, element.description);
        formData.append(`tickets[${index}][price]`, element.price);
      });

    }else if (key === 'tags') {
      
      data[key].forEach((element:string) => {
        formData.append(`${key}[]`, element);
      });
    }else if (key === 'authorized_scanners') {
      
      data[key].forEach((element:string) => {
        formData.append(`${key}[]`, element);
      });
    }
    else {
      formData.append(key, typeof(data[key]) === 'boolean' ? data[key].toString() : data[key]);
    }
  }

  
  var config = {
      method: 'post',
      url:`${API_URL}event/update/${id}`,
      headers: { 
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'multipart/form-data'
      },
      data: formData
  };

  let result = await axios(config)
  
  .then((response) => {
    if (response.data.error) {
      let err_msg = '';
      for (let key in response.data.message) {
        err_msg += err_msg.length !== 0 ? ` - ${response.data.message[key]}` : response.data.message[key];
        return {
          error: true,
          message: err_msg
        };
      }
    }else {
      return {
        error: false,
        message:'Event Updated'
      };
    }
      
  })
  .catch((error) => {
    try {
      if (error.response.data.error) {
        return {
          error: true,
          message: error.response.data.message
        };
      }else {
        return {
          error: true,
          message: 'Unable to complete event update.'
        };
      }
    }catch {
      return {
        error: true,
        message: 'Server error'
      };
    }
    
  })
  return result;
}

export const fetchResaleTickets = async (token:string) => {
  
  var config = {
      method: 'GET',
      url:`${RESALE_URL}ticket_sale/?token=${token}`,
      headers: { 
        'Content-Type': 'application/json'
      }
  };
  let result = await axios(config)
  
  .then((response) => {
    return {
      error: false,
      data: response.data.tickets
    };
  })
  .catch((error) => {
    try {
      return {
        error: true,
        message: error.response.data.message
      };
    }catch {
      return {
        error: true,
        message: 'Server error'
      };
    }
    
  })
  return result;
}

export const fetchResaleTicketsById = async (ticket_id:String, token:string) => {
  
  var config = {
      method: 'GET',
      url:`${RESALE_URL}ticket_sale/get/${ticket_id}/?token=${token}`,
      headers: { 
        'Content-Type': 'application/json'
      }
  };
  let result = await axios(config)
  
  .then((response) => {
    return {
      error: false,
      data: response.data.tickets
    };
  })
  .catch((error) => {
    try {
      return {
        error: true,
        message: error.response.data.message
      };
    }catch {
      return {
        error: true,
        message: 'Server error'
      };
    }
    
  })
  return result;
}

export const ticketPurchase = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}ticket/purchase`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      let err_msg = '';
      if (typeof(error.response.data.message) === 'object') {
        for (let key in error.response.data.message) {
          err_msg += err_msg.length !== 0 ? ` - ${error.response.data.message[key]}` : error.response.data.message[key];
        }
      }else {
        err_msg = error.response.data.message;
      }
      return {
        error: true,
        message: err_msg
      };
      // return {
      //   error: true,
      //   message: error.response.data.message
      // };
    }else {
      return {
        error: true,
        message: 'Unable to complete ticket purchase. Kindly contact support.'
      };
    }
  })

  return result;
}

export const ticketPrePurchase = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}ticket/pre/purchase`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      let err_msg = '';
      if (typeof(error.response.data.message) === 'object') {
        for (let key in error.response.data.message) {
          err_msg += err_msg.length !== 0 ? ` - ${error.response.data.message[key]}` : error.response.data.message[key];
        }
      }else {
        err_msg = error.response.data.message;
      }
      return {
        error: true,
        message: err_msg
      };
      // return {
      //   error: true,
      //   message: error.response.data.message
      // };
    }else {
      return {
        error: true,
        message: 'Unable to complete ticket purchase. Kindly contact support.'
      };
    }
  })

  return result;
}

export const ticketPurchaseComplete = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}ticket/complete/purchase`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      let err_msg = '';
      if (typeof(error.response.data.message) === 'object') {
        for (let key in error.response.data.message) {
          err_msg += err_msg.length !== 0 ? ` - ${error.response.data.message[key]}` : error.response.data.message[key];
        }
      }else {
        err_msg = error.response.data.message;
      }
      return {
        error: true,
        message: err_msg
      };
      // return {
      //   error: true,
      //   message: error.response.data.message
      // };
    }else {
      return {
        error: true,
        message: 'Unable to complete ticket purchase. Kindly contact support.'
      };
    }
  })

  return result;
}

export const ticketDiscountCode = async (data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}discount_code/apply`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      let err_msg = '';
      if (typeof(error.response.data.message) === 'object') {
        for (let key in error.response.data.message) {
          err_msg += err_msg.length !== 0 ? ` - ${error.response.data.message[key]}` : error.response.data.message[key];
        }
      }else {
        err_msg = error.response.data.message;
      }
      
      return {
        error: true,
        message: err_msg
      };
      // return {
      //   error: true,
      //   message: error.response.data.message
      // };
    }else {
      return {
        error: true,
        message: 'Unable to verify discount code. Kindly contact support.'
      };
    }
  })

  return result;
}

export const ticketPurchaseVerify = async (data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}paystack/verify`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      let err_msg = '';
      if (typeof(error.response.data.message) === 'object') {
        for (let key in error.response.data.message) {
          err_msg += err_msg.length !== 0 ? ` - ${error.response.data.message[key]}` : error.response.data.message[key];
        }
      }else {
        err_msg = error.response.data.message;
      }
      return {
        error: true,
        message: err_msg
      };
    }else {
      return {
        error: true,
        message: 'Verification failed'
      };
    }
  })

  return result;
}

export const fetchUserTickets = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}tickets`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to get user tickets record."
      }
  })

  return result;
}

export const fetchUserWallet = async (token:string) => {
  var config = {
    method: 'get',
    url:`${API_URL}wallet`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to get user wallet the record."
      }
  })

  return result;
}

export const scanTicketApi = async (token:string, ticketId:string | number) => {
  var config = {
    method: 'post',
    url:`${API_URL}ticket/scan/${ticketId}`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      return {
        error: true,
        message: error.response.data.message
      };
    }else {
      return {
        error: true,
        message: 'Unable to scan user ticket. Kindly try again.'
      };
    }
  })

  return result;
}

export const triggerWithdrawal = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}wallet/withdraw`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      return {
        status: false,
        message: error.response.data.message,
        data: error.response.data
      };
    }else {
      return {
        status: false,
        message: 'Unable to initiate withdrawal. Kindly check your internet and try again.'
      };
    }
  })

  return result;
}

export const triggerEventWithdrawal = async (token:string, data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}wallet/event/withdraw`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
    if (error.response.data.error) {
      return {
        status: false,
        message: error.response.data.message,
        data: error.response.data
      };
    }else {
      return {
        status: false,
        message: 'Unable to initiate withdrawal. Kindly try again.'
      };
    }
  })

  return result;
}

// export const toggleEventSaved = async (event_id, token) => {
//   var config = {
//     method: 'get',
//     url:`${API_URL}event/save?event_id=${event_id}`,
//     headers: { 
//       'Authorization': `Bearer ${token}`, 
//       'Content-Type': 'application/json'
//     }
//   };

//   let result = await axios(config)
//   .then((response) => {
//       return {
//         status: true,
//         data: response.data
//       }
//   })
//   .catch((error) => {
//     console.log(error);
//       return {
//         status:false,
//         message: "Unable to save event."
//       }
//   })

//   return result;
// }


export const addEventComment = async (token:string, event_id:string, comment:string) => {
  var config = {
    method: 'post',
    url:`${API_URL}event/comment/${event_id}`,
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: {
      comment: comment
    }
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      alert('User not found');
      return {
        status:false,
        message: "Unable to add your comment."
      }
  })

  return result;
}

export const searchUserEvent = async (token:string|null, query:string) => {
  let headers:generalObj = {  
    'Content-Type': 'application/json'
  }
  
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  var config = {
    method: 'get',
    url:`${API_URL}event/search?search_query=${query}`,
    headers: headers
  };

  let result = await axios(config)
  .then((response) => {
    return {
      status: true,
      data: response.data
    }
  })
  .catch((error) => {
      return {
        status:false,
        message: "Unable to search the record."
      }
  })

  return result;
}

export const resetPassword = async (data:object) => {
  var config = {
    method: 'post',
    url:`${API_URL}password/reset`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data
  };

  let result = await axios(config)
  .then((response) => {
    return true
  })
  .catch((error) => {
    if (error.response && error.response.data.error) {
      if (typeof(error.response.data.message) === 'object') {
        if (error.response.data.message.email) {
          alert(error.response.data.message.email);
        }
        if (error.response.data.message.token) {
          alert(error.response.data.message.token);
        }
      }else {
        alert(error.response.data.message);
      }
    }else {
        alert('Error connecting to server, try again later');
    }
    return false;
  })

  return result;
}

// export const addPayAccount = async (token, data) => {
//   var config = {
//     method: 'post',
//     url:`${API_URL}bank-account/add`,
//     headers: { 
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json'
//     },
//     data: data
//   };

//   let result = await axios(config)
//   .then((response) => {
//     return {
//       status: true,
//       data: response.data
//     }
//   })
//   .catch((error) => {
//     if (error.response.data.error) {
//       return {
//         error: true,
//         message: error.response.data.message
//       };
//     }else {
//       return {
//         error: true,
//         message: 'Unable to add payment account.'
//       };
//     }
//   })

//   return result;
// }

/* End of API function */